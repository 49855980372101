import React, { useEffect, useState } from "react";
import './Services.css'
import { Category } from "../types/services";

const ServicesPage: React.FC = () => {

    const [serviceData, setData] = useState<Category[]>()
    useEffect(() => {
        const getLocationResults = async () => {
            const locationResult = await fetch('https://api.studiodluxestl.com/products/categories/all');
            let services = await locationResult.json();
            setData(services);
        };
        getLocationResults();
    }, [])


    if (!serviceData) return <div>Loading ...</div>

    function categoryNotes(params: Category) {
        switch (params.id) {
            case 1: 
            return 'Cost of Hair Not Included in Pricing';

            case 2: 
            return 'All Chemical Services Include Integrity Insurance Treatment, and complimentary haircut and style.'
        }
    }

    return (
        <>
          <h3>Your dream hair, untangled!</h3>
          {serviceData.map(categories => {
            return (
              <>
                <div id={`category-${categories.id}`} className="category" key={categories.id}>
                    <img loading="lazy" src={categories.category_image}/>
                    <h2>{ categories.name }</h2>
                </div>
                {categories.products.map((product, i) => {
                  let productName = product.product.name;
                  if (categories.products[i - 1]?.product.name === product.product.name) {
                    productName = ""
                  }
                  return(
                    <div className="pricing" key={product.product.id}>
                      <p>{ productName}</p>
                      <p>{product.product.price.display_price}</p>
                    </div>
                  )
                })}
                <p className="category-notes">{categories.category_notes}</p>
              </>
            )
          })}
          <div className="policies">
            <h2>Financing</h2>
            <p>Studio D Luxe now has a buy now, pay later option! This allows you to finance services, products, and hair extensions for 3, 6, 12, or 24 months. With no hard credit check, you’re approved in 60 seconds! ($200 minimum to finance)</p>
            <p><a href='http://pay.withcherry.com/studiodluxestl'>Cick Here to Finance!</a></p>
            <h3>Payment Estimator</h3>
            <p>$500 finance <br></br>$71.43 due at appointment, $71.43 per month</p>
            <p>$1,000 finance <br></br>$142.86 due at appointment, $142.86 per month</p>
            <p>$1,500 finance <br></br> $214.29 due at appointment, $214.29 per month</p>
            <p>$2,000 finance <br></br> $285.71 due at appointment, $285.71 per month</p>
            <p>This is an example for 6 month financing options only. Exact terms and interest depend on credit score and other factors.</p>

            <h2>Cancellation</h2>
            <p>Studio D Luxe has a 24 hour cancellation policy.
                While I understand that life happens, this
                business is appointment-based so please
                communicate your time constraints accordingly.
                If you need to cancel or reschedule during the 24-hour
                window, you will be charged 100% of your scheduled appointment. </p>
            
            <h2>No Show</h2>
            <p>If you do not show up for your appointment, you will be charged 100% of your scheduled service.</p>
            
            <h2>Late Arrival</h2>
            <p>If you arrive more than 10 minutes late to your appointment, I may not be able to complete your service. If rescheduling is necessary, please communicate any time constraints clearly to avoid future delays.</p>
          </div>
        </>
    )
}

export default ServicesPage;