enum Folder {
    HOME = 'homegallery/small',
    CONTACT = 'contactpage/small',
    REVIEW = 'reviews/small'
}

enum FileExtension {
    JPEG = '.jpeg',
    PNG = '.png'
}

export const galleryData = {
    imageUrl: "https://studiodluxestl.com/assets/images/",
    // imageUrl: "http://localhost:3000/assets/images/",
    homeGallery: [
        `${Folder.HOME}/client${FileExtension.JPEG}`, 
        `${Folder.HOME}/extensions${FileExtension.JPEG}`,
        `${Folder.HOME}/customer${FileExtension.JPEG}`,
        `${Folder.HOME}/highlights${FileExtension.JPEG}`,
        `${Folder.HOME}/towels${FileExtension.JPEG}`,
        `${Folder.HOME}/china${FileExtension.JPEG}`,
        `${Folder.HOME}/51${FileExtension.JPEG}`,
        `${Folder.HOME}/59${FileExtension.JPEG}`,
        `${Folder.HOME}/52${FileExtension.JPEG}`,
        `${Folder.HOME}/74${FileExtension.JPEG}`
    ],
    contactGallery: [
        `${Folder.CONTACT}/outside${FileExtension.JPEG}`, 
        `${Folder.CONTACT}/IMG_4989${FileExtension.JPEG}`
        // `${Folder.CONTACT}/chair${FileExtension.JPEG}`, 
        // `${Folder.CONTACT}/washing${FileExtension.JPEG}`
    ],
    reviewGallery: [
        `${Folder.REVIEW}/review1${FileExtension.PNG}`,
        `${Folder.REVIEW}/review2${FileExtension.PNG}`,
        `${Folder.REVIEW}/review3${FileExtension.PNG}`,
        `${Folder.REVIEW}/review4${FileExtension.PNG}`,
        `${Folder.REVIEW}/review5${FileExtension.PNG}`,
        `${Folder.REVIEW}/review6${FileExtension.PNG}`,
        `${Folder.REVIEW}/review7${FileExtension.PNG}`,
        `${Folder.REVIEW}/review8${FileExtension.PNG}`,
        `${Folder.REVIEW}/review9${FileExtension.PNG}`,
        `${Folder.REVIEW}/review10${FileExtension.PNG}`
    ]
}
