import { heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp } from 'ionicons/icons';

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
  }  

export const appPages: AppPage[] = [
    {
      title: 'Home',
      url: '/',
      iosIcon: mailOutline,
      mdIcon: mailSharp
    },
    {
      title: 'Get To Know Me',
      url: '/your-stylist',
      iosIcon: paperPlaneOutline,
      mdIcon: paperPlaneSharp
    },
    {
      title: 'Services/Policies',
      url: '/services',
      iosIcon: heartOutline,
      mdIcon: heartSharp
    },
    {
      title: 'Extensions',
      url: '/extensions',
      iosIcon: heartOutline,
      mdIcon: heartSharp
    },
    {
        title: 'Ammonia Free',
        url: '/ammonia-free',
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp
    },
    {
      title: 'Reviews',
      url: '/reviews',
      iosIcon: mailOutline,
      mdIcon: mailSharp
    },
    {
      title: 'Contact',
      url: '/contact',
      iosIcon: heartOutline,
      mdIcon: heartSharp
    },
    
  ];
  