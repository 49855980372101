import React, { useEffect, useState } from 'react';
import './Contact.css';
import './Page.css';
import ImageGallery from '../components/ImageGallery';
import { ClassNameData } from '../data/enums';
import ReserveButton from '../components/ReserveButton';
import { LocationData } from '../types/location';

const dayOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function formatHours(time: string) {
    const hours = parseInt(time.split(":")[0]);
    const mins = time.split(":")[1];
    if (hours > 12) {
        const newHours = hours-12
        return `${newHours+':'+mins+' PM'}`;
    } else {
        return `${hours+':'+mins+' AM'}`;
    }
}

const ContactPage: React.FC = () => {
    const [locationData, setData] = useState<LocationData>()
    useEffect(() => {
        const getLocationResults = async () => {
            const locationResult = await fetch('https://api.studiodluxestl.com/location/1');
            let location = await locationResult.json();
            setData(location);
        };
        getLocationResults();
    }, [])
    
    if (!locationData) return <div>Loading ...</div>;
    
    return (
        <div className={ClassNameData.CONTACT_PAGE}>
            <h3 className='tagline'>Your dream hair, untangled!</h3>
            <h1 className ='newguests'>New Guests</h1>
                <p>To book online, click the button below. When booking online, please include a complimentary consultation to your first appointment so we have plenty of time to discuss your dream hair.</p> 
                <p>If you have any questions, please don't hesitate to reach out via phone, text message, email, or social media. </p>
                <p>Thank you for trusting me with your confidence, and I cannot wait to meet you!</p>&nbsp;
                <ReserveButton/>
            <h1 className='location'>Location</h1>
            <div className="address">
                {locationData.address.map(address => {
                    return(                    
                    <>
                        <p>{address.address1}</p>
                        <p>{address.address2}</p>
                        <p>{address.city}, {address.state} {address.zipcode}</p>
                    </>)
                })}
            </div>&nbsp;
                <p>Studio D Luxe is located inside of Image Studios, Warson Woods.</p>
                <p>You can find image studios in a strip setback from Manchester Road with plenty of parking out front.</p>
                <p>When you enter image studios, suite 112 is on the right side of the hallway about 3/4 of the way in.</p>           
            <h1>Call/Text</h1>
            <p>{locationData.phone}</p>
            <p className={ClassNameData.EMAIL}>{locationData.email}</p>
            <p className={ClassNameData.EMAIL}></p>
            <h1>Business Hours</h1>
            {locationData.location_hours.sort((a, b) => a.dayOfWeek - b.dayOfWeek).map((hours, index) => {
                if(hours.opens) {
                    return(
                        <React.Fragment key={index}>
                            <p><b><strong>{dayOfWeek[hours.dayOfWeek-1]} | {formatHours(hours.opens)} - {formatHours(hours.closes)}</strong></b></p>
                        </React.Fragment>
                    )    
                } else {
                    return(
                        <React.Fragment key={index}>
                            <p>{dayOfWeek[hours.dayOfWeek-1]} | Closed</p>
                        </React.Fragment>
                    )    
                }
            })} 
            &nbsp;<p className={ClassNameData.WALK_INS}><strong>Appointment only</strong><br></br>
            &nbsp;Join waitlist for last minunte reservations</p>
            <ImageGallery location={window.location.pathname}/>
            <footer></footer>
        </div>
       
    )    
}

export default ContactPage;