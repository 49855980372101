import React from "react";
import './GetToKnowPage.css';
import { questionnaire } from "../data/questionaire.data";

export const GetToKnowPage: React.FC = () => {
    return (
        <React.Fragment>
            <h4>Your dream hair, untangled!</h4>
            <div className="imgContainer">
                <img loading="lazy" src="assets/images/extensions.jpg" alt="" />
            </div>
            <div className="row">
                <div className="imgContainer2">
                    <img loading="lazy" src="assets/images/book.jpg" alt="" />
                </div>
                <div className="imgContainer2">
                    <img loading="lazy" src="assets/images/silly.jpg" alt="" />
                </div>
            </div>
           
            <h3>My Career As A Stylist</h3>
                {/* <p>I specialize in ammonia free hair color, and giving you the hair of your dreams through Invisible Bead Extensions®.</p> */} 
                <p className="paragraph">My favorite part about being a hair stylist is creating a professional relationship with everyone in my chair, and becoming a part of their life to help them see their best self inside and out. While my precision haircuts are UNMATCHED, I specialize in ammonia free hair color, and am certified in the one and only Invisible Bead Extensions®.</p>
                <p className="paragraph">I also educate for ID Hair North America, which has turned me into a total hair nerd. As an educator, you can ask me anything about any product in my salon and I’ll have a very calculated answer for you. Nevertheless, as a human being, I’m still learning and growing every day.</p>
                <p className="paragraph">My priority behind the chair is making sure each head of hair is easy to work with in between appointments. Most hair isn't getting the proper nutrients to stay healthy, so when I'm able to find what each strand needs to make it more manageable, it makes my heart skip a beat!</p>
                <p className="paragraph">I believe building a relationship is based on communication and ideas, and I can’t wait to show you all that I have!</p>
                {questionnaire.map((question, index) => {
                    return (
                        <div key={index} className="questionWrapper">
                            <div className="questionContent">{question.question}</div>
                            <p className="answerContent">{question.answer}</p>
                        </div>
                    )
                })}
                {/* <footer></footer> */}
        </React.Fragment>
    )
};

export default GetToKnowPage;