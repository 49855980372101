import './Page.css';
import './AboutUs.css'
import ReserveButton from '../components/ReserveButton';


import React from "react";
import ImageGallery from '../components/ImageGallery';
import NewClient from '../components/NewGuests';

const HomePage: React.FC = () => {

    return (
        <React.Fragment>
            <h3>Your dream hair, untangled!</h3>
            <h1 className="aboutHeader">Welcome!</h1>
            <NewClient/>
            <ReserveButton/>&nbsp;
            <div className="imageContainer">
                <img loading="lazy" src="assets/images/d.jpg" alt="" />
            </div>
            <h2 className="message">A Message From <br></br>The Owner</h2>
            <p>Studio D Luxe is a salon suite located inside of Image Studios, Warson Woods that provides a wide range of services, including but not limited to ammonia free blonding and coloring, precision cutting, and hand tied extensions! What sets this salon apart from others is that Studio D Luxe is individual based. This means that when you're here, the only focus is YOU! Gone are the days where you have 6 random people working on your hair to get you in and out as quickly as possible. At Studio D Luxe, calm is key for your hair and lifestyle. While you're waiting for your next appointment, check out the "Get to Know Me" tab where you can learn more about yours truly. I look forward to making all of your hair dreams come true!</p>
            <p className="best">All The Best,</p>
            <div className="signature">Darlene "D" McLeod</div>
            <ImageGallery location={window.location.pathname}/>
            {/* <footer className="home"></footer> */}
        </React.Fragment>
    )
}

export default HomePage;